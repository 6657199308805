import React, { FunctionComponent, useState, useCallback } from 'react';
import { UserResponse } from '../../models/user.model';
import ClientList from '../layout/ClientList';
import ClientForm from '../layout/ClientForm';
import './ClientPage.scss';

const ClientPage: FunctionComponent = () => {
    const [selctedUser, setSelectedUser] = useState<UserResponse>();


    const selectUser = useCallback((user: UserResponse) => {
        setSelectedUser(user)
    }, [])

    const resetUser = useCallback(() => {
        setSelectedUser(undefined);
    }, []);

    return (
        <div className="user-main">
            <ClientList selectUser={selectUser} />
            <ClientForm selectedUser={selctedUser} resetUser={resetUser} />
        </div>
    );
}
export default ClientPage;