import React, { FunctionComponent, useEffect, useState, useCallback } from 'react';
import { UserResponse } from '../../models/user.model';
import { PageRequest, Page } from '../../models/page.model';
import './ClientList.scss';
import { sectionMapping } from '../../models/section.model';
import { priceFormatter } from '../../utils';
import { useToast } from '../../hooks';
import userService from '../../services/user.service';
import Spinner from '../misc/Spinner';

interface ClientListProps {
    selectUser: (user: UserResponse) => void;
}

const ClientList: FunctionComponent<ClientListProps> = (props) => {
    const [isLoading, setLoading] = useState(false);
    const [pageRequest, setPageRequest] = useState(new PageRequest());
    const [pageResponse, setPageResponse] = useState<Page<UserResponse>>();

    const createToast = useToast();

    const fetchOrderItems = useCallback((showLoader: boolean) => {
        if (showLoader) {
            setLoading(true);
        }

        return userService.getUsers(pageRequest)
            .then(
                page => {
                    setPageResponse(page);
                },
                e => createToast('error', e.response ? e.response.data.message : "Le serveur n'est pas disponible")
            )
            .finally(() => setLoading(false));
    }, [pageRequest, createToast]);


    const previousPage = useCallback(() => {
        if (pageResponse?.first) {
            return;
        }

        setPageRequest(pageRequest.previous());
    }, [pageResponse, pageRequest]);

    const nextPage = useCallback(() => {
        if (pageResponse?.last) {
            return;
        }

        setPageRequest(pageRequest.next());
    }, [pageResponse, pageRequest]);

    useEffect(() => {
        fetchOrderItems(true);
    }, [fetchOrderItems]);

    return (

        <div className="user-list">
            <h3>Liste de clients</h3>
            {isLoading ?
                <Spinner /> :
                <table>
                    <thead>
                        <tr>
                            <th className="user-last-name">Nom</th>
                            <th className="user-first-name">Prénom</th>
                            <th className="user-nick-name">Surnom</th>
                            <th className="user-section">Catégorie</th>
                            <th className="user-credit">Solde</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            pageResponse?.content.map((user, index) => (
                                <tr key={index} onClick={() => props.selectUser(user)}>
                                    <td className="user-last-name">{user.lastName}</td>
                                    <td className="user-first-name">{user.firstName}</td>
                                    <td className="user-nick-name">{user.nickName}</td>
                                    <td className="user-section">
                                        {
                                            sectionMapping.find((s) => s.value === user.section)?.label
                                        }
                                    </td>
                                    <td className="user-credit">{priceFormatter.format(user.credit)}</td>
                                </tr>
                            ))
                        }

                    </tbody>
                </table>
            }
            <br />
            <div className="delivery-pagination columns space-around">
                <button type="button" className="secondary" onClick={previousPage} disabled={pageResponse?.first}>&laquo; Précédent</button>
                <button type="button" className="secondary" onClick={nextPage} disabled={pageResponse?.last}>Suivant &raquo;</button>
            </div>
            <br />
        </div>
    );
}

export default ClientList;
